<template>
  <div class="h100 d-flex align-items-center"></div>
</template>

<script>
import axios from 'axios'
import yookassaTypes from '@/store/yookassa/types'
import appTypes from '@/store/app/types'
import { mapActions, mapMutations } from 'vuex'

// http://localhost:8081/yookassa-completion-payment/?code=%D0%9A%D1%83%D0%BF%D0%B8%D1%82%D1%8C&payment_id=27b4f2aa-000f-5000-9000-1f4ab286a584&return_url=https%3A%2F%2Fgoogle.com&session_id=asdf-dfdfd-dfgkhjfbnvf12345-df

// http://vidget.lctest.ru/yookassa-completion-payment/?code=${code}&payment_id=${payment_id}&return_url=${return_url}/&session_id=${session_id}

export default {
  components: {},
  data: () => ({
    returnUrl: null
  }),
  methods: {
    ...mapMutations('yookassa', [yookassaTypes.SET_YOOKASSA_PAYMENT]),
    ...mapActions('app', [
      appTypes.SET_APP_CODE_ACTION,
      appTypes.SET_APP_CODE_ACTION_FOR_PAYMENTS
    ]),
    async readParams() {
      const { code, sessionId, returnUrl, paymentId } = this.$route.query
      if (returnUrl) {
        this.returnUrl = returnUrl
      }
      if (code) {
        await this[appTypes.SET_APP_CODE_ACTION_FOR_PAYMENTS]({
          code: code,
          paymentId,
          sessionId
        })
      }
    }
    // getPaymentStatus() {
    //   const { paymentId } = this.$route.query
    //   console.log(paymentId)
    //   if (paymentId) {
    //     const data = {
    //       paymentId
    //     }
    //     axios
    //       .get('https://widget.mltest.site/yookassa/api/payment/', {
    //         params: {
    //           id: paymentId
    //         }
    //       })
    //       .then(response => {
    //         console.log('STATUS', response)
    //         if (response?.status === 200) {
    //           let { data } = response
    //           this[yookassaTypes.SET_YOOKASSA_PAYMENT](data)
    //           // if (this.returnUrl) {
    //           //   window.open(this.returnUrl, '_blank')
    //           // }
    //         }
    //         // this.$router.push('/payment-result')
    //       })
    //   }
    // }
  },
  mounted() {
    setTimeout(() => {
      this.readParams()
      // this.getPaymentStatus()
    }, 2000)
  }
}
</script>
